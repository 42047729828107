import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ButtonGroup, Fieldset, Legend, Submit, TextInputControl, Alert, Button } from '@ver-uds/uswds-react';
import { UserStatus } from '@vis-auth/vis-user-client';
import DHSFormWrapper from '../DHSFormWrapper/DHSFormWrapper';
import { useFeatureFlags } from '../FeatureFlags/FeatureFlagsContext';

interface OneTimeLoginFormProps {
  logonId?: string;
  userStatus: UserStatus;
  forgotUserIdUrl: string;
  onSubmit: (userId: string) => Promise<void>;
  onCancel: () => void;
  errorMessage?: string;
}

const OneTimeLoginForm = ({
  logonId,
  userStatus,
  forgotUserIdUrl,
  onSubmit,
  onCancel,
  errorMessage,
}: OneTimeLoginFormProps): JSX.Element => {
  // TODO: status should come from react-query managing the
  // `onSubmit` action, but we can't use that in forgot-password.tsx
  // because `useQuery` can't be called conditionally (where OneTimeLoginForm is called conditionally).
  // When we can refactor forgot-password to separate pages, we can use react-query and remove this status.
  const [status, setStatus] = useState('idle');
  const { showAccountLockedLDGBanner } = useFeatureFlags();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<{ userId: string }>({
    reValidateMode: 'onSubmit',
  });
  const userId = watch('userId') ?? logonId;
  const alertElement = useRef<HTMLDivElement>(null);

  let headerText = 'Reset Password';
  if (userStatus === UserStatus.LOCKED || userStatus === UserStatus.DEACTIVATED) {
    headerText = 'Account Locked';
  }

  return (
    <>
      <Helmet>
        <title>{headerText}</title>
      </Helmet>
      <DHSFormWrapper>
        <form
          noValidate
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(async (): Promise<void> => {
            setStatus('loading');

            await onSubmit(userId);
            if (alertElement.current) {
              // no need to set status to `idle` if we've navigated away (plus it will cause a warning)
              setStatus('idle');
              alertElement.current.focus();
            }
          })}
        >
          <Fieldset>
            <Legend>
              <h2>{headerText}</h2>
              {showAccountLockedLDGBanner && (
                <Alert status="info" slim>
                  <Alert.Text>
                    <span style={{ fontWeight: 400 }}>
                      If your user account has already been migrated, you will need to reset your password with
                      Login.gov.
                    </span>
                  </Alert.Text>
                </Alert>
              )}
              {userStatus !== UserStatus.LOCKED && userStatus !== UserStatus.DEACTIVATED && <h3>Enter User ID</h3>}
            </Legend>

            {errorMessage ? (
              <div id="user-id-error" className="margin-y-3" tabIndex={-1} ref={alertElement}>
                <Alert status="error">
                  <Alert.Heading>Error</Alert.Heading>
                  {errorMessage.split('\r\n').map(
                    (err, i) =>
                      err && (
                        <>
                          {i !== 0 && <br />}
                          <Alert.Text key={err}>{err}</Alert.Text>
                        </>
                      ),
                  )}
                </Alert>
              </div>
            ) : null}
            {userStatus === UserStatus.LOCKED && (
              <Alert status="error">
                <Alert.Text>
                  {`Your account has been locked, and you need to reset your password to unlock your account. Click the "Reset Password" button to begin the process.`}
                </Alert.Text>
              </Alert>
            )}
            {userStatus === UserStatus.DEACTIVATED && (
              <Alert status="error">
                <Alert.Text>
                  {`Your account has been locked due to inactivity, and you need to reset your password to unlock your account. Click the "Reset Password" button to begin the process.`}
                </Alert.Text>
              </Alert>
            )}
            {userStatus === UserStatus.LOCKED || userStatus === UserStatus.DEACTIVATED ? (
              <div className="margin-y3">
                <h3 className="usa-label">User ID</h3>
                {logonId}
              </div>
            ) : (
              <TextInputControl
                id="userId"
                className="margin-bottom-1"
                required
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('userId', {
                  required: 'User ID is required',
                  minLength: {
                    value: 8,
                    message: 'Must be exactly 8 alpha-numeric characters',
                  },
                })}
                errorId="user-id-error"
                errorMessage={errors.userId && errors.userId.message}
                type="text"
                label="User ID"
                maxLength={8}
                minLength={8}
                defaultValue={logonId}
              />
            )}
            {userStatus !== UserStatus.LOCKED && userStatus !== UserStatus.DEACTIVATED && (
              <Link to={forgotUserIdUrl} className="text-no-wrap">
                Forgot User ID
              </Link>
            )}

            <ButtonGroup className="margin-top-4">
              <Button name="cancel" type="button" variant="outline" onClick={onCancel}>
                Cancel
              </Button>
              <Submit name="reset password" value="Reset Password" disabled={status === 'loading'} />
            </ButtonGroup>
          </Fieldset>
        </form>
      </DHSFormWrapper>
    </>
  );
};

OneTimeLoginForm.defaultProps = {
  logonId: null,
  errorMessage: '',
};

export default OneTimeLoginForm;
