/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { TextInputControl, Submit, Legend, Fieldset, Alert } from '@ver-uds/uswds-react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@ver-uds/react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Application, AuthorizationResponse, UserStatus } from '@vis-auth/vis-user-client';
import { Client } from '../components/Providers/OAuthParamsParser';
import { useUserData, UserData } from '../components/Providers/UserDataProvider';
import UseAgreementModal from '../components/Modals/UseAgreementModal';
import PrivacyActModal from '../components/Modals/PrivacyActModal';
import PaperworkReductionActModal from '../components/Modals/PaperworkReductionActModal';
import { oidcApi } from '../userService';
import DHSFormWrapper from '../components/DHSFormWrapper/DHSFormWrapper';
import mapErrors from '../map-errors';
import { useEnvironment } from '../components/Environment/EnvironmentContext';
import IEDeprecationAlert from '../components/IEDeprecationAlert/IEDeprecationAlert';
import { useFeatureFlags } from '../components/FeatureFlags/FeatureFlagsContext';
import EveOutageWarning from './eve-outage-warning';
import LoginDotGovLogo from '../images/loginDotGovLogo.svg';

declare global {
  interface Document {
    documentMode?: number;
  }
}

function getBrandName(client: string): string {
  if (client === Client.EVERIFY) {
    return 'E-Verify';
  }

  if (client === Client.SAVE) {
    return 'SAVE';
  }

  return client;
}

async function loginPost({
  username,
  password,
  params,
}: {
  username: string;
  password: string;
  params: UserData;
}): Promise<AuthorizationResponse> {
  const { client_id, code_challenge, code_challenge_method, scope, state, redirect_uri } = params;

  try {
    const response = await oidcApi.authorize({
      clientId: client_id,
      redirectUri: redirect_uri,
      codeChallenge: code_challenge,
      codeChallengeMethod: code_challenge_method,
      username,
      password,
      scope,
      state,
    });

    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error', err);
    // The error caught is a Response type object, not an Error. Re-throw a true Error.
    throw new Error(await mapErrors(err as Response, true));
  }
}

const LegalContent = ({ client }: { client: string }): JSX.Element => {
  return (
    <ul className="margin-top-3 add-list-reset">
      {client === Client.EVERIFY ? (
        <>
          <li>
            <PaperworkReductionActModal app={Application.EVERIFY} />
          </li>
          <li>
            OMB Control No. 1615-0092 <br />
            Expiration Date 5/31/2027
          </li>
        </>
      ) : (
        <>
          <li>
            <PaperworkReductionActModal app={Application.SAVE} />
          </li>
          <li>
            <PrivacyActModal />
          </li>
        </>
      )}
    </ul>
  );
};

const Login = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const alertElement = useRef<HTMLInputElement>(null);
  const IERef = useRef<HTMLDivElement>(null);
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();
  const env = useEnvironment();
  const { showEveOutageBanner, isLoginDotGovEnabledSAVE, isLoginDotGovEnabledEVerify } = useFeatureFlags();

  const userData = useUserData();

  const {
    client,
    redirect_uri,
    state,
    authorizationCode,
    setUserStatus,
    setUserId,
    setLogonId,
    setAuthorizationCode,
    calcDaysToPasswordExpire,
  } = userData;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ username: string; password: string }>({
    reValidateMode: 'onSubmit',
  });
  const [username, password] = watch(['username', 'password']);

  const [mutate, { status, isIdle: isLoggingInIdle, error }] = useMutation(loginPost, {
    onSuccess: (response: AuthorizationResponse) => {
      if (response?.user?.status === UserStatus.LOCKED) {
        setUserId(response.user.id);
        setLogonId(response.user.logonId);
        setUserStatus(UserStatus.LOCKED);
        history.push(`/account-locked/user-id`);
        return;
      }

      if (response?.user?.roles.includes('WEB_SERVICE')) {
        throw new Error(
          'Web Service user accounts cannot login to this application. Try using a different user account to login.',
        );
      }

      if (response.authorizationCode) {
        setAuthorizationCode(response.authorizationCode);
      }

      if (response.user) {
        setUserId(response.user.id);
        setLogonId(response?.user.logonId);
      }

      if (response.user?.status === UserStatus.DEACTIVATED) {
        setUserStatus(UserStatus.DEACTIVATED);
        history.push(`/account-locked/deactivated`);
        return;
      }

      let daysToExpire = -1;
      if (response.user?.passwordExpirationDate) {
        daysToExpire = calcDaysToPasswordExpire(response.user.passwordExpirationDate);
      }

      if (
        response.user?.status === UserStatus.PASSWORDCHANGEREQUIRED ||
        response.user?.status === UserStatus.PENDINGREACTIVATION
      ) {
        setUserStatus(UserStatus.PASSWORDCHANGEREQUIRED);
        history.push('/password-expired/change-password');
        return;
      }

      // need to check that date is not in the past (already expired) and within the next 7 days, otherwise login
      if (daysToExpire > 0 && daysToExpire <= 7) {
        history.push('/password-expiring');
      } else {
        window.location.replace(`${redirect_uri}?code=${response.authorizationCode}&state=${state}`);
      }
    },
  });

  let errorMessage;

  if (error instanceof Error) {
    errorMessage = error.message;

    if (errorMessage.includes('Unable to log in')) {
      errorMessage = (
        <span>
          {error.message}
          <a href={redirect_uri}>Return to the previous site</a> to try again.
        </span>
      );
    } else if (errorMessage.includes('You do not have access')) {
      errorMessage = errorMessage.replaceAll('{{app}}', getBrandName(client));
    }
  }

  // If we are not attempting to login and there is an authorization code already present for this session
  // bail out to the redirect URI because the code challenge has been consumed.
  if (isLoggingInIdle && authorizationCode) {
    window.location.replace(redirect_uri);
  }

  const getLoginDotGovHref = (): string => {
    return `${redirect_uri}/loginDotGov`;
  };

  const getEnrollmentHref = (): string => {
    let enrollmentHref = env.NEW_EVE_ENROLLMENT_ENABLED ? `/enroll/${client.toLowerCase()}` : env.EVERIFY_ENROLL;
    if (client === Client.EVERIFY && isLoginDotGovEnabledEVerify) {
      enrollmentHref = env.EVERIFY_MFA_ENROLL;
    }
    return enrollmentHref;
  };

  const showEveAlert = (condition: boolean): boolean => {
    return client === Client.EVERIFY && condition;
  };

  const showLoginErrorMessage = (clientEnum: string, message: string | undefined): string | undefined | boolean => {
    return client === clientEnum && message;
  };

  const showLDGBanner = (): boolean => {
    return (
      (client === Client.EVERIFY && isLoginDotGovEnabledEVerify) || (client === Client.SAVE && isLoginDotGovEnabledSAVE)
    );
  };

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <UseAgreementModal />
      <div className="grid-col" style={{ paddingLeft: '0', paddingRight: '0' }}>
        {showEveAlert(showEveOutageBanner) ? <EveOutageWarning /> : null}
        {showLDGBanner() && (
          <div className="margin-x-auto grid-col tablet:grid-col-7 desktop:grid-col-6 radius-md margin-bottom-3">
            <Alert status="info">
              <Alert.Heading>New! Sign in with Login.gov</Alert.Heading>
              <Alert.Text>
                Login.gov is a sign in service that offers secure and private access to{' '}
                {client === Client.EVERIFY ? 'E-Verify' : 'SAVE'}.{' '}
                {/* <a href="/">Learn how to migrate your account to Login.gov</a>. */}
              </Alert.Text>
            </Alert>
          </div>
        )}
        <DHSFormWrapper>
          <form
            noValidate
            aria-label="Sign In"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(async (): Promise<void> => {
              const isIE = !!document.documentMode;

              if (isIE && client === Client.EVERIFY) {
                setShowAlert(true);
                IERef?.current?.focus();
              } else {
                await mutate({ username, password, params: userData });

                if (alertElement.current) {
                  alertElement.current.focus();
                }
              }
            })}
          >
            <Fieldset>
              <Legend>
                <h2>Sign In</h2>
              </Legend>
              <TextInputControl
                id="username"
                className="margin-bottom-1"
                label="User ID"
                required
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('username', {
                  required: 'User ID is required',
                  minLength: {
                    value: 8,
                    message: 'Must be exactly 8 alpha-numeric characters',
                  },
                })}
                errorMessage={errors.username && errors.username.message}
                errorId="username-error"
                maxLength={8}
                minLength={8}
              />
              <Link to="/forgot-user-id" className="text-no-wrap">
                Forgot User ID
              </Link>

              <TextInputControl
                id="password"
                className="margin-bottom-1"
                label="Password"
                required
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('password', { required: 'Password is required' })}
                errorMessage={errors.password && errors.password.message}
                errorId="password-error"
                type={showPassword ? 'text' : 'password'}
              />
              <div className="display-flex flex-justify">
                <Link to="/forgot-password/user-id" className="text-no-wrap">
                  Forgot Password
                </Link>
                <Button
                  type="button"
                  variant="tertiary"
                  className="margin-top-05 margin-left-2 text-right"
                  aria-controls="password"
                  onClick={(): void => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide password' : 'Show password'}
                </Button>
              </div>

              {showLoginErrorMessage(Client.EVERIFY, env.EVERIFY_LOGIN_ERROR_MESSAGE) ? (
                <Alert status="error">
                  <Alert.Text>{env.EVERIFY_LOGIN_ERROR_MESSAGE}</Alert.Text>
                </Alert>
              ) : null}
              {showLoginErrorMessage(Client.SAVE, env.SAVE_LOGIN_ERROR_MESSAGE) ? (
                <Alert status="error">
                  <Alert.Text>{env.SAVE_LOGIN_ERROR_MESSAGE}</Alert.Text>
                </Alert>
              ) : null}
              {showEveAlert(showAlert) ? (
                <div id="ie-deprecation-alert" tabIndex={-1} ref={IERef}>
                  <IEDeprecationAlert showAlert={showAlert} />
                </div>
              ) : null}
              {errorMessage ? (
                <div id="sign-in-error" className="margin-y-3" tabIndex={-1} ref={alertElement}>
                  <Alert status="error">
                    <Alert.Text>{errorMessage}</Alert.Text>
                  </Alert>
                </div>
              ) : null}

              {client === Client.EVERIFY ? (
                <div className="margin-top-3">
                  <Submit className="margin-bottom-3 width-full" value="Sign In" disabled={status === 'loading'} />
                  {isLoginDotGovEnabledEVerify && (
                    <>
                      <hr />
                      <div className="margin-top-4 margin-bottom-4">
                        <h4>Already migrated?</h4>
                        <Button
                          className="margin-top-1 width-full text-black"
                          type="button"
                          variant="secondary"
                          onClick={() => window.location.replace(getLoginDotGovHref())}
                        >
                          Sign In with <span className="usa-sr-only">Login.Gov</span>{' '}
                          <img src={LoginDotGovLogo} alt="" className="margin-left-1" />
                        </Button>
                      </div>
                    </>
                  )}
                  <hr />
                  <h4>Want to participate in E-Verify?</h4>
                  <p>
                    If your company does not have an account, you can <a href={getEnrollmentHref()}>enroll today!</a>
                  </p>
                </div>
              ) : (
                <>
                  <Submit
                    className="margin-top-4 margin-bottom-4 width-full"
                    value="Sign In"
                    disabled={status === 'loading'}
                  />
                  {isLoginDotGovEnabledSAVE && (
                    <>
                      <hr />
                      <div className="margin-top-4 margin-bottom-4">
                        <h4>Already migrated?</h4>
                        <Button
                          className="margin-top-1 width-full text-black"
                          type="button"
                          variant="secondary"
                          onClick={() => window.location.replace(getLoginDotGovHref())}
                        >
                          Sign In with <span className="usa-sr-only">Login.Gov</span>{' '}
                          <img src={LoginDotGovLogo} alt="" className="margin-left-1" />
                        </Button>
                      </div>
                    </>
                  )}
                  {client === Client.SAVE && env.SAVE_SSA_LOGON && (
                    <div>
                      <hr />
                      <h4>SSA Employee?</h4>
                      <p>
                        If you are an employee of the Social Security Administration,{' '}
                        <a href={env.SAVE_SSA_LOGON}>click here to sign in to SAVE.</a>
                      </p>
                    </div>
                  )}
                </>
              )}
            </Fieldset>
          </form>
          <hr className="margin-top-4" />
          <h3>Legal</h3>
          <LegalContent client={client} />
        </DHSFormWrapper>
      </div>
    </>
  );
};

export default Login;
