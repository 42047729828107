/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Application, UserStatus } from '@vis-auth/vis-user-client';
import { useHistory } from 'react-router-dom';
import { legacyIdpApi } from '../userService';
import ChangePasswordForm from '../components/ChangePasswordForm/ChangePasswordForm';
import { useUserData } from '../components/Providers/UserDataProvider';
import AlertPage from './alert-page';
import mapErrors from '../map-errors';
import { Client } from '../components/Providers/OAuthParamsParser';
import { useEnvironment } from '../components/Environment/EnvironmentContext';
import OneTimeLoginForm from '../components/OneTimeLoginForm/OneTimeLoginForm';
import { useFeatureFlags } from '../components/FeatureFlags/FeatureFlagsContext';

const oneTimeLogin = async (logonId: string, client: string) => {
  try {
    const result = await legacyIdpApi.sendOTC({
      sendOneTimeCodeRequest: {
        logonId,
        app: client as Application,
      },
    });

    return result;
  } catch (err) {
    throw new Error(await mapErrors(err as Response));
  }
};

async function setPassword({ userId, newPassword }: { userId: string; newPassword: string }): Promise<boolean> {
  try {
    await legacyIdpApi.updatePassword({
      id: userId,
      passwordChangeBody: {
        newPassword,
      },
    });
    return true;
  } catch (err) {
    // The error caught is a Response type object, not an Error. Re-throw a true Error.
    throw new Error(await mapErrors(err as Response));
  }
}

function getBrandName(client: string): string {
  if (client === Client.EVERIFY) {
    return 'E-Verify';
  }

  if (client === Client.SAVE) {
    return 'SAVE';
  }

  return client;
}

const ForgotPassword = (): JSX.Element => {
  const [userIdError, setUserIdError] = useState('');

  const env = useEnvironment();
  const userData = useUserData();
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const { client, userId, logonId, userStatus } = userData;

  let errorMessage = '';

  const [mutate, { status: setPasswordStatus, error }] = useMutation(setPassword);

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  const onCancel = (): void => {
    history.push('/');
  };

  if (history.location.pathname.includes('/change-password') && userId) {
    const onSubmit = async ({ newPassword }: { newPassword: string }): Promise<void> => {
      const result = await mutate({ userId, newPassword });
      if (result) {
        history.push('password-confirmation');
      }
    };

    return (
      <ChangePasswordForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        helmet="Reset Password"
        title="Reset Password"
        subtitle="Choose New Password"
        submitBtnText="Change Password"
        confirmPassLabel="Confirm New Password"
        errorMessage={errorMessage}
        status={setPasswordStatus}
      />
    );
  }

  if (history.location.pathname.includes('/deactivated') || history.location.pathname.includes('/user-id')) {
    return (
      <OneTimeLoginForm
        logonId={logonId}
        forgotUserIdUrl="/forgot-user-id"
        userStatus={userStatus ?? UserStatus.UNKNOWN}
        onSubmit={async (u): Promise<void> => {
          return oneTimeLogin(u, client)
            .then(() => {
              history.push(`/change-pw/otc-message-sent?app=${client}&userStatus=${userStatus ?? UserStatus.UNKNOWN}`);
            })
            .catch((apiError: Error) => {
              setUserIdError(apiError.message.replaceAll('{{app}}', getBrandName(client)));
            });
        }}
        onCancel={onCancel}
        errorMessage={userIdError}
      />
    );
  }

  if (!userId) {
    throw new Error('User ID missing.');
  }

  if (
    client === Client.EVERIFY &&
    env.NEW_EVE_ENROLLMENT_ENABLED &&
    userData?.userStatus !== UserStatus.DEACTIVATED &&
    userData?.userStatus !== UserStatus.LOCKED
  ) {
    return (
      <AlertPage
        title="Reset Password"
        alertHeading="We are unable to reset your password"
        alertText={
          <>
            If you are a user of an enrolled E-Verify account, please contact your program administrator.
            <br />
            If you are enrolling a company in E-Verify, please restart the E-Verify Enrollment process.
          </>
        }
        continueBtnText="Restart E-Verify Enrollment"
        continueBtnUrl={featureFlags.isLoginDotGovEnabledEVerify ? env.EVERIFY_MFA_ENROLL : '/enroll/everify'}
      />
    );
  }

  if (
    client === Client.SAVE &&
    userData?.userStatus !== UserStatus.DEACTIVATED &&
    userData?.userStatus !== UserStatus.LOCKED
  ) {
    return (
      <AlertPage
        title="Reset Password"
        alertHeading="We are unable to reset your password"
        alertText={
          <>
            If you are a user of an enrolled SAVE Agency, please contact your program administrator.
            <br />
            If you are enrolling an agency in SAVE, please restart the SAVE Enrollment process.
          </>
        }
        continueBtnText="Restart SAVE Enrollment"
        continueBtnUrl="/enroll/save"
      />
    );
  }

  return <AlertPage />;
};

export default ForgotPassword;
